import { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  defer,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import OffersView from "./view";
import SidebarNavigation from "@components/SidebarNavigation";
import { navigationData } from "./data";
import { decryptText } from "@library/enc-dec";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as OfferServices from "@services/Offers";
import * as OfferActions from "@redux/actions/Offers";
import { checkLoginAndRole } from "@helpers/Login/";
import { produce } from "immer";
import Swal from "sweetalert2";
import { toggleFullscreen, convertToISOFormat } from "@helpers/common.js";

var listOfCheckedOffers = [];

function Offers(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));

  const navigate = useNavigate();

  let authToken = decryptText(localStorage.getItem("aEmediat"));

  // UseState's to control the functionality of setting visibility offers
  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
  const [disableSaveBtnOfVisibility, setDisableSaveBtnOfVisibility] =
    useState(false);
  const visibilityPopupContainerRef = useRef(null);
  const [
    is_VisibilityPopupContainerRef_Visible,
    set_Is_VisibilityPopupContainerRef_Visible,
  ] = useState(false);
  const [
    visibilityPopupContainerRef_Position,
    set_VisibilityPopupContainerRef_Position,
  ] = useState({ top: 0, left: 0 });
  const [currentSelectedOffer, setCurrentSelectedOffer] = useState(null);

  // UseState's to control the functionality of setting Active offers
  const [internalVisibility, setInternalVisibility] = useState("disabled");

  const [disableSaveBtnOfActive, setDisableSaveBtnOfActive] = useState(false);
  const activePopupContainerRef = useRef(null);
  const [
    is_ActivePopupContainerRef_Visible,
    set_Is_ActivePopupContainerRef_Visible,
  ] = useState(false);
  const [
    activePopupContainerRef_Position,
    set_ActivePopupContainerRef_Position,
  ] = useState({ top: 0, left: 0 });

  // To handle thumbnail & title
  const thubmnailPopupContainerRef = useRef(null);
  const [
    is_ThubmnailPopupContainerRef_Visible,
    set_Is_ThubmnailPopupContainerRef_Visible,
  ] = useState(false);
  const [
    thubmnailPopupContainerRef_Position,
    set_ThubmnailPopupContainerRef_Position,
  ] = useState({ top: 0, left: 0 });

  const [updatedOfferTitle, setUpdatedOfferTitle] = useState("");
  const [updatedInternalTitle, setUpdatedInternalTitle] = useState("");
  const [thumbnailFileObj, setThumbnailFileObj] = useState("");
  const [offerUpdateTilteError, setOfferUpdateTilteError] = useState(false);

  const [deferedData, setDeferedData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams({
      page: props.filters.page,
      limit: props.filters.limit,
    });
  }, []);

  useEffect(() => {
    checkLoginAndRole("offers");
    if(props.filters.limit>0){
    getAllOffers();}
  }, [props.filters]);

  useEffect(() => {
    if (!Number(limit) && Number(page)) {
      return;
    }
    let updatedFilters = produce(props.filters, (draft) => {
      draft.page = page;
      draft.limit = limit;
    });
    props.updateFilters(updatedFilters);
  }, [page, limit]);

  const getAllOffers = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: props.filters.page,
      limit: props.filters.limit,
      isSorted: props.filters.isSorted,
      sortBy: props.filters.sortBy,
      searchText: props.filters.searchText,
      startDate: props.filters.startDate,
      endDate: props.filters.endDate,
    };

    var deferedResponse = defer({
      res: OfferServices.getAllOffers(payload, authToken),
    });
    setDeferedData(deferedResponse);
    deferedResponse.data.res
      .then((response) => {
        props.updateAllOffers(response);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedOffers = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  // Handle when visibility btn is click which will update the val of radio btns as per the selected course
  const handleVisibilityBtnClick = (event, offerId, offer) => {
    set_Is_VisibilityPopupContainerRef_Visible(true);
    let currentEle = event.target;

    let left = currentEle.getBoundingClientRect().left;
    let top = window.scrollY + currentEle.getBoundingClientRect().bottom;
    set_VisibilityPopupContainerRef_Position({ left: left, top: top });

    setInternalVisibility("disabled");

    if (offerId === "bulk") {
      setDisableSaveBtnOfVisibility(true);
      setCurrentSelectedOffer(offerId);
    } else {
      setCurrentSelectedOffer(offerId);
      setInternalVisibility(offer.visibility);
    }
  };

  // Handle when Active btn is click which will update the val of radio btns as per the selected course
  const handleActiveBtnClick = (event, offerId) => {
    set_Is_ActivePopupContainerRef_Visible(true);
    let currentEle = event.target;

    let left = currentEle.getBoundingClientRect().left - 100;
    let top = window.scrollY + currentEle.getBoundingClientRect().bottom;
    set_ActivePopupContainerRef_Position({ left: left, top: top });

    // setInternalIsActive(false);
    // setInternalIsInactive(false);
    setDisableSaveBtnOfActive(false);

    if (offerId === "bulk") {
      setDisableSaveBtnOfActive(true);
      setCurrentSelectedOffer(offerId);
    } else {
      setCurrentSelectedOffer(offerId);
      const offer = props.offerSet.find((item) => item._id === offerId);

      if (offer.isActive) {
        // setInternalIsActive(true);
        // setInternalIsInactive(false);
      } else {
        // setInternalIsInactive(true);
        // setInternalIsActive(false);
      }
    }
  };

  // To open thumbnail update popup
  const handleDoubleClick = (
    event,
    offerDocId,
    offerTitle,
    offerInternalTitle
  ) => {
    let currentEle = event.target;
    set_Is_ThubmnailPopupContainerRef_Visible(true);

    let left = currentEle.getBoundingClientRect().left;
    let top = window.scrollY + currentEle.getBoundingClientRect().bottom;

    set_ThubmnailPopupContainerRef_Position({ left: left, top: top });

    setUpdatedOfferTitle(offerTitle);
    setUpdatedInternalTitle(offerInternalTitle);

    setCurrentSelectedOffer(offerDocId);
  };

  // To close the visibility popup container
  const handleThumbnailCancleBtnClick = (event) => {
    set_Is_ThubmnailPopupContainerRef_Visible(false);
    setOfferUpdateTilteError(false);
  };

  const handleSaveThumbnail_Title = async (event) => {
    try {
      set_Is_ThubmnailPopupContainerRef_Visible(false);
      if (updatedOfferTitle !== "" || thumbnailFileObj !== "") {
        const payload = {
          offerId: currentSelectedOffer,
          title: updatedOfferTitle,
          internalTitle: updatedInternalTitle,
          thumbnailUrl: thumbnailFileObj,
        };

        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let response = await OfferServices.updateOfferThumbnail(
          payload,
          authToken
        );

        if (response.success) {
          let updatedAllOffers = produce(props.allOffers, (draft) => {
            draft.data = draft.data.map((data) => {
              if (currentSelectedOffer === data._id) {
                data.title = response.data.title;
                data.thumbnailUrl = response.data.thumbnailUrl;
                data.internalTitle = response.data.internalTitle;
              }
              return data;
            });
          });
          props.updateAllOffers(updatedAllOffers);
          var deferredResponse = defer({ res: updatedAllOffers });
          setDeferedData(deferredResponse);
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }

        setUpdatedOfferTitle("");
        setThumbnailFileObj("");
        document.getElementById("coverImage_name").innerHTML =
          "Upload cover picture";
        setOfferUpdateTilteError(false);
      } else {
        setOfferUpdateTilteError(true);
      }
    } catch (err) {
      console.log("Error coming while saving title and thubnail", err);
    }
  };

  const handleDeleteBulk = async () => {
    try {
      const payload = {
        offerIds: listOfCheckedOffers.map((item) => item._id),
      };
      let response = await OfferServices.deleteOffers(payload, authToken);
      if (response.success) {
        getAllOffers();
        listOfCheckedOffers = [];
        document.getElementById("bulkCheckbox").checked = false;
        setDisableBulkActionBtn(true);
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while deleting the offers", err);
    }
  };

  const handleFileUpload = (event) => {
    let fileInptId = event.target.id.split("_")[0];
    document.getElementById(fileInptId).click();
    document
      .getElementById(fileInptId)
      .addEventListener("change", function (event) {
        let fileName = this.value.replace(/C:\\fakepath\\/i, "");
        document.getElementById("coverImage_name").innerHTML = fileName;
      });
  };

  const handleUpdateCourseTitle = (event) => {
    setUpdatedOfferTitle(event.target.value);
  };

  const handleUpdateInternalTitle = (event) => {
    setUpdatedInternalTitle(event.target.value);
  };

  const convertToBase64 = (event) => {
    setThumbnailFileObj(event.target.files[0]);
  };

  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };

  const handleRedirectToHome = (event, videoDocId, defaultComponent) => {
    //Handle setting default page while navigating
    let finalNavigationData = [];
    finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      // VAR x ONLY FOR TESTING
      // let x = 'VideoComments';
      // finalNavigationData = setDefaultNavigation(x);
      finalNavigationData = setDefaultNavigation("VideoDetails");
    }
    finalNavigationData[0].route = `/offers?page=${page}&limit=${limit}`;
    if (videoDocId === "none") {
      navigate("/offers_", { state: { finalNavigationData } });
    } else {
      navigate("/offers/" + videoDocId, { state: { finalNavigationData } });
    }
  };

  // To handle radio btn of public and unlisted
  const handleVisibilityRadioBtns = (event, value) => {
    setInternalVisibility(value);
    setDisableSaveBtnOfVisibility(false);
  };

  // To handle radio btn of active and inactive
  const handleActiveRadioBtns = (event, value) => {
    if (value === "active") {
      // setInternalIsActive(true);
      // setInternalIsInactive(false);
    } else {
      // setInternalIsActive(false);
      // setInternalIsInactive(true);
    }
    setDisableSaveBtnOfActive(false);
  };

  // Handle hiding of popup's when clicked outside the respective container
  const handleOutsideClick = (event) => {
    if (
      visibilityPopupContainerRef.current &&
      !visibilityPopupContainerRef.current.contains(event.target)
    ) {
      set_Is_VisibilityPopupContainerRef_Visible(false);
    }

    if (
      thubmnailPopupContainerRef.current &&
      !thubmnailPopupContainerRef.current.contains(event.target)
    ) {
      set_Is_ThubmnailPopupContainerRef_Visible(false);
      setOfferUpdateTilteError(false);
    }
  };

  // Handle visibility save button
  const handleSaveBtnVisibility = (event) => {
    currentSelectedOffer === "bulk"
      ? handleOfferVisibility(listOfCheckedOffers.map((item) => item._id))
      : handleOfferVisibility([currentSelectedOffer]);
    listOfCheckedOffers = [];
    setDisableBulkActionBtn(true);
    set_Is_VisibilityPopupContainerRef_Visible(false);
  };

  // Handle active save button
  const handleSaveBtnActive = (event) => {
    if (currentSelectedOffer != "bulk") {
      listOfCheckedOffers.push(currentSelectedOffer);
    }
    handleOfferActive(internalVisibility, listOfCheckedOffers);
    set_Is_ActivePopupContainerRef_Visible(false);
    listOfCheckedOffers = [];
    setDisableBulkActionBtn(true);
  };

  const handleOfferVisibility = async (offerIds) => {
    try {
      const payload = { offerIds: offerIds, visibility: internalVisibility };
      let response = await OfferServices.updateOffersVisibility(
        payload,
        authToken
      );

      if (response.success) {
        let updatedAllOffers = produce(props.allOffers, (draft) => {
          draft.data = draft.data.map((data) => {
            if (offerIds.includes(data._id)) {
              data.visibility = internalVisibility;
            }
            return data;
          });
        });
        props.updateAllOffers(updatedAllOffers);
        var deferredResponse = defer({ res: updatedAllOffers });
        setDeferedData(deferredResponse);
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while handling video visiblity", err);
    }
  };

  // To close the visibility popup container
  const handleVisibilityCancleBtnClick = (event) => {
    set_Is_VisibilityPopupContainerRef_Visible(false);
  };
  // To close the active popup container
  const handleActiveCancleBtnClick = (event) => {
    set_Is_ActivePopupContainerRef_Visible(false);
  };

  const handleOfferActive = async (isActive, offerIds) => {
    try {
      const isActiveList = [];
      for (let i = 0; i < offerIds.length; i++) {
        isActive ? isActiveList.push(true) : isActiveList.push(false);
      }

      // let response = await OfferServices.updateOfferValues(
      // 	offerIds,
      // 	'isActive',
      // 	isActiveList,
      // 	authToken
      // );
      // if (response.success) {
      // 	getAllOffers();
      // 	return;
      // }
      // alert(response.message);
    } catch (err) {
      console.log("Error coming while handling offer active status", err);
    }
  };

  const handleSort = (data) => {
    const mapping = {
      "Date Added": "createdAt",
      "Last modified date": "updatedAt",
    };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    let updatedFilters = produce(props.filters, (draft) => {
      draft.sortBy = id;
      draft.isSorted = data[0]?.desc ? data[0].desc : false;
    });
    props.updateFilters(updatedFilters);
  };
  const handleSearch = (data) => {
    let updatedFilters = produce(props.filters, (draft) => {
      draft.page = 0;
      draft.searchText = data.text;
      draft.startDate = data.startDate;
      draft.endDate = data.endDate;
    });
    props.updateFilters(updatedFilters);
  };

  const handleClearFilter = (index) => {
    let updatedFilters = produce(props.filters, (draft) => {
      if (index === 0) {
        draft.searchText = "";
      } else if (index === 1) {
        draft.startDate = "";
        draft.endDate = "";
      }
    });
    // setSearchFilters(updatedFilters)
    props.updateFilters(updatedFilters);
  };

  const { offerSet, allOffers } = props;

  return (
    <>
      <SidebarNavigation />
      <OffersView
        deferedData={deferedData}
        showThumbnail={true}
        handleRedirectToHome={handleRedirectToHome}
        allOffers={allOffers}
        offerSet={offerSet}
        handleOutsideClick={handleOutsideClick}
        //START : UseStates & fn to control the functionality of updating thumbnail of video
        handleDoubleClick={handleDoubleClick}
        handleThumbnailCancleBtnClick={handleThumbnailCancleBtnClick}
        thubmnailPopupContainerRef={thubmnailPopupContainerRef}
        is_ThubmnailPopupContainerRef_Visible={
          is_ThubmnailPopupContainerRef_Visible
        }
        thubmnailPopupContainerRef_Position={
          thubmnailPopupContainerRef_Position
        }
        updatedOfferTitle={updatedOfferTitle}
        updatedInternalTitle={updatedInternalTitle}
        offerUpdateTilteError={offerUpdateTilteError}
        handleUpdateCourseTitle={handleUpdateCourseTitle}
        handleUpdateInternalTitle={handleUpdateInternalTitle}
        handleSaveThumbnail_Title={handleSaveThumbnail_Title}
        handleFileUpload={handleFileUpload}
        convertToBase64={convertToBase64}
        //START : UseStates & fn to control the functionality of updating visibility of offer
        visibilityPopupContainerRef={visibilityPopupContainerRef}
        is_VisibilityPopupContainerRef_Visible={
          is_VisibilityPopupContainerRef_Visible
        }
        visibilityPopupContainerRef_Position={
          visibilityPopupContainerRef_Position
        }
        internalVisibility={internalVisibility}
        handleVisibilityBtnClick={handleVisibilityBtnClick}
        handleSaveBtnVisibility={handleSaveBtnVisibility}
        handleVisibilityCancleBtnClick={handleVisibilityCancleBtnClick}
        handleVisibilityRadioBtns={handleVisibilityRadioBtns}
        //END : UseStates & fn to control the functionality of updating visibility of offer

        //START : UseStates & fn to control the functionality of upating Active status of offer
        ActivePopupContainerRef={activePopupContainerRef}
        is_ActivePopupContainerRef_Visible={is_ActivePopupContainerRef_Visible}
        activePopupContainerRef_Position={activePopupContainerRef_Position}
        handleStatusBtnClick={handleActiveBtnClick}
        handleSaveBtnActive={handleSaveBtnActive}
        handleActiveCancleBtnClick={handleActiveCancleBtnClick}
        handleActiveRadioBtns={handleActiveRadioBtns}
        //END : UseStates & fn to control the functionality of upating active status of offer

        //START : UseStates & fn for the functionality of bulk actions
        disableSaveBtnOfVisibility={disableSaveBtnOfVisibility}
        disableBulkActionBtn={disableBulkActionBtn}
        handleDeleteBulk={handleDeleteBulk}
        //END : UseStates & fn for the functionality of bulk actions

        //START : UseStates & fn to control the functionality of checkboxes
        handleCheckBoxes={handleCheckBoxes}
        //END : UseStates & fn to control the functionality of checkboxes

        //START : USeState and fn to handle the sorting
        handleSort={handleSort}
        //END

        filters={props.filters}
        handleSearch={handleSearch}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allOffers: state.offers.allOffers,
    //totalNumberOfOffers: state.Offers.totalNumberOfOffers,
    offerSet: state.offers.offerSet,
    filters: state.offers.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllOffers: OfferActions.updateAllOffers,
      //updateTotalNumOfOffers: OfferActions.updateTotalNumOfOffers,
      updateOfferSet: OfferActions.updateOfferSet,
      updateFilters: OfferActions.updateFilters,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
