import React, { useEffect, useState } from 'react';

import SelectionPopUpView from './view';

function SelectionPopUp(props) {
    const[data, setData] = useState(props.data);
    const[optionKey, setOptionKey]=useState(props.optionKey);
	const[dataKey, setDataKey]=useState(props.dataKey? props.dataKey: "_id");
	const[selectedItemsKey, setSelectedItemsKey]=useState(props.selectedItemsKey? props.selectedItemsKey: "_id");
	const[selectedItems, setSelectedItems]=useState(props.selectedItems? props.selectedItems:[]);
	const[selectionType, setSelectionType] = useState(props.selectionType? props.selectionType: 'single'); //multiple,single
	const[searchText, setSearchText]=useState('')

    useEffect(()=>{
		setData(props.data)
	},[props.data])
   
	const handleSelect=(item)=>{
		if(selectionType=='single')
		{
			setSelectedItems([item])
		}
		else{
			var updatedSelection = [...selectedItems]
			const selectedIndex = selectedItems.findIndex(selected => item && selected && selected[selectedItemsKey] === item[dataKey]);
			if (selectedIndex !== -1) {
					updatedSelection.splice(selectedIndex, 1);
			  } else {
				updatedSelection.push(item);
			  }
			  setSelectedItems(updatedSelection)
		}
	}
	const handleSearch=(value)=>{
	setSearchText(value)
	}

	const handleCancel=(e)=>{
		props.handleCancel(e)
	}
	const handleSave=(e)=>{
		props.handleSave(selectedItems, e)
	}
	
	return (
		<SelectionPopUpView
		   title = {props.title}
		   isSearchEnabled={props.isSearchEnabled}
		   searchPlaceholder={props.searchPlaceholder}
		   height={props.height}
		   width={props.width}
		   data = {data}	
           optionKey = {optionKey}
		   dataKey={dataKey}
		   selectedItemsKey={selectedItemsKey}
           selectedItems = {selectedItems}
		   selectionType={selectionType}
		   searchText={searchText}
		   handleSearch = {handleSearch}
		   handleSelect={handleSelect}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default SelectionPopUp;
